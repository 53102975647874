<template>
  <div class="viewDemandAgency">
    <div class="header">
      <div>
        <span class="institutionName">{{demandParamsName}}</span>项目
      </div>
      <el-button type="primary" @click="exportDemand">导出</el-button>
    </div>
    <el-table
      class="ly_table"
      :data="viewDemandAgencyTable"
      :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="projectName" label="项目名称" align="center" />
      <el-table-column label="所在地区" align="center">
        <template v-slot="{row}">
          {{row.projectCountry?row.projectCountry:''}}
          {{row.projectProvince?'-'+row.projectProvince:''}}
          {{row.projectCity?'-'+row.projectCity:''}}
        </template>
      </el-table-column>
      <el-table-column prop="projectFieldType" label="领域类别" align="center" />
      <el-table-column prop="projectIndustryType" label="行业类别" align="center" />
      <el-table-column prop="projectStage" label="项目阶段" align="center" />
      <el-table-column prop="linkman" label="联系人" align="center" />
      <el-table-column prop="companyLinkman" label="联系电话" align="center" />
      <el-table-column prop="companyLinkEmail" label="联系邮箱" align="center" />
      <el-table-column label="操作" width="150" align="center">
        <template v-slot="{row}">
          <button-table @buttonClick="viewItem(row)" content="查看" icon="jr-iconjr-icon-eye" />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="queryInfo.pageNum"
      :page-sizes="[3, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import ButtonTable from '@/components/ButtonTable.vue'
import { pagination } from '@/mixins/pagination'
import { getProjectDemandList } from '@/api/numberProject'
import { exportData } from '@/utils/tool'
export default {
  name: 'ViewDemandAgency',

  components: {
    ButtonTable,
  },

  mixins: [pagination],

  data() {
    return {
      viewDemandAgencyTable: [],
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
      },
      ids: [],
    }
  },
  computed: {
    demandParams() {
      return this.$route.query.demandParams
    },
    demandParamsName() {
      return this.$route.query.demandParamsName
    },
  },
  created() {
    this.search()
  },
  methods: {
    viewItem(row) {
      this.$router.push({
        name: 'ProjectDataInfo',
        query: {
          projectDataType: 1,
          id: row.id,
          demandType: '需求机构模块',
        },
      })
    },
    async search() {
      Object.assign(this.queryInfo, { id: this.demandParams })

      const res = await getProjectDemandList(this.queryInfo)

      if (res.code === 200) {
        this.viewDemandAgencyTable = res.data.list
      }

      this.total = res.data.total
    },
    handleSelectionChange(val) {
      this.ids = val.map((item) => {
        return item.id
      })
    },
    exportDemand() {
      if (this.ids.length) {
        const params = {
          id: this.demandParams,
          companyCode: this.ids,
        }

        exportData(
          params,
          '/projectDemand/exportExcel',
          'application/msexcel',
          this.search,
          '需求机构数据.xls'
        )
      } else {
        this.$message.warning('请选择要导出的数据')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.viewDemandAgency {
  background: #fff;
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    .institutionName {
      color: #1e9fff;
      font-size: 18px;
      margin-right: 5px;
    }
  }
}
</style>
